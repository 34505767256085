import React, { useState } from "react";
import "../sass/ActivityLog.scss";
import ReactModal from "react-modal";
import axios from "axios";
// import EditIcon from "../images/EditIconGreen.svg";
// import DeleteIcon from "../images/DeleteIcon.svg";
import ResultIcon from "../images/ResultIcon.svg";
import EditActivityModal from "./EditActivityModal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import SendEmailModal from "./SendEmail/SendEmailModal";
import "../sass/JoditActivity.scss";
import JoditEditor from "jodit-react";
import "jodit";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import ReusableModal from "./Reusables/ReusableModal";
import DropdownActivity from "./Dropdown/DropdownActivity";
import { Ban, FileText, MailOpen, TriangleAlert } from "lucide-react";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";

const config = {
  height: "auto",
  minHeight: "20px",
  width: "inherit",
  minWidth: "20px",
  readonly: true,
  toolbar: false,
  enableDragAndDropFileToEditor: true,
  askBeforePasteFromWord: false,
  askBeforePasteHTML: false,
  link: {
    modeClassName: false,
  },
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
  toolbarSticky: true,
  enter: "BR",
  placeholder: "Type your message...",
  zIndex: 2147483640,
};

const Activity = ({
  time,
  icon,
  name,
  title,
  description,
  created_by,
  opportunityName,
  whiteSpace,
  id,
  account_id,
  contact_id,
  carrier_id,
  opportunity_id,
  result_id,
  type,
  category_id,
  result_name,
  opportunity_name,
  account_name,
  contact_name,
  carrier_name,
  descriptionFull,
  loadActivities,
  metadata,
  parentId,
  integration,
  is_pinned,
  pinnedId,
  metadataType,
  iconExtension,
  customerName,
  toEmail,
  mailProvider,
  attachments,
  quoteData,
  loadQuoteActivities,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPinnedModal, setShowPinnedModal] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);

  const pinActivity = () => {
    setPinLoading(true);
    if (pinnedId !== id) {
      axios({
        method: "PATCH",
        url: `/activities/${pinnedId}`,
        data: {
          type: "unpin",
        },
      });
    }
    axios({
      method: "PATCH",
      url: `/activities/${id}`,
      data: {
        type: is_pinned ? "unpin" : "pin",
      },
    }).then((res) => {
      let message = is_pinned ? "unpinned" : "pinned";
      NotificationManager.success(`Activity ${message}`);
      loadActivities(1, "refetch");
      setPinLoading(false);
      setShowPinnedModal(false);
    });
  };
  const send_at = metadata?.send_at ? new Date(metadata?.send_at) : new Date();

  return (
    <div
      className="activityLog__activity"
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      <EditActivityModal
        showOpportunityModal={showEditModal}
        setShowOpportunityModal={() => setShowEditModal(true)}
        handleCloseModal={() => setShowEditModal(false)}
        id={id}
        name={title}
        description={descriptionFull}
        account_id={account_id}
        carrier_id={carrier_id}
        opportunity_id={opportunity_id}
        category_id={category_id}
        result_id={result_id}
        type={type}
        loadActivities={loadActivities}
        metadata={metadata}
        quoteData={quoteData}
      />
      <DeleteActivityModal
        showOpportunityModal={showDeleteModal}
        setShowOpportunityModal={() => setShowDeleteModal(true)}
        handleCloseModal={() => setShowDeleteModal(false)}
        id={id}
        name={title}
        description={description}
        account_id={account_id}
        carrier_id={carrier_id}
        opportunity_id={opportunity_id}
        category_id={category_id}
        result_id={result_id}
        type={type}
        loadActivities={loadActivities}
        loadQuoteActivities={loadQuoteActivities}
      />
      <SendEmailModal
        showOpportunityModal={showModal}
        setShowOpportunityModal={() => setShowModal(true)}
        handleCloseModal={() => setShowModal(false)}
        replyToEmail={
          metadata?.status === "received"
            ? metadata?.from_email
            : metadata?.status === "reply"
            ? toEmail
            : metadata?.to_email
        }
        replySentBy={metadata?.service_provider}
        accountId={account_id}
        carrierId={carrier_id}
        opportunityId={opportunity_id}
        contactId={contact_id}
        isReply={true}
        activityId={id}
        parentId={parentId}
        subjectReplied={title}
        loadActivities={loadActivities}
        integration={integration}
        recordName={opportunityName ? opportunityName : customerName}
      />
      <ReusableModal
        showModal={showPinnedModal}
        handleCloseModal={() => setShowPinnedModal(false)}
        submitHandler={pinActivity}
        modalHeading={is_pinned ? `Unpin an activity` : `Pin an activity`}
        modalPara={
          is_pinned
            ? `Are you sure you want to unpin this activity`
            : `Are you sure you want to pin this activity`
        }
        modalHeight={`30`}
        modalTop={`25`}
        loading={pinLoading}
      />
      <ReactTooltip />
      <div className="activityLog__activity-time">
        {time}{" "}
        {is_pinned && (
          <div
            style={{
              float: "right",
              display: "inline-block",
              color: "#ff8800",
            }}
          >
            <i className="fa fa-thumb-tack" aria-hidden="true"></i> Pinned
          </div>
        )}
      </div>
      <div className="activity-area" style={{ marginBottom: "35px" }}>
        {showButtons && (
          <div
            style={{
              position: "absolute",
              right: "2%",
              top: "4px",
              background: "transparent",
              cursor: "pointer",
              zIndex: 100,
            }}
          >
            <DropdownActivity
              setShowPinnedModal={setShowPinnedModal}
              setShowEditModal={setShowEditModal}
              setShowDeleteModal={setShowDeleteModal}
              isPinned={is_pinned}
            />
          </div>
        )}
        <div
          className="activityLog__activity-desc"
          style={
            is_pinned
              ? {
                  border: "1px solid #FF9B81",
                  boxShadow: "0 0 0 3px rgb(255,237,233)",
                }
              : metadata?.unsubscribed
              ? { border: "1px solid #FED5D6" }
              : {}
          }
        >
          <div style={{ display: "inline-block" }}>
            {icon !== "OtherGreen" &&
              (icon === "QuoteGreen" ? (
                <RequestQuoteOutlinedIcon
                  className="a-type"
                  fontSize="small"
                  sx={{ color: "#62CA9D" }}
                />
              ) : (
                <img
                  src={require(`../images/${icon}.${iconExtension}`)}
                  alt=""
                  width={16}
                  className="a-type"
                  style={
                    icon === "calendlyIcon"
                      ? {
                          height: "25px",
                          transform: "translate(-11px, 0px)",
                          width: "40px",
                          display: "inline",
                        }
                      : { display: "inline" }
                  }
                />
              ))}
          </div>
          <div
            style={{
              display: "inline-block",
              marginLeft: "45px",
              paddingRight: "10px",
            }}
          >
            <div className="activityLog__activity-name">
              {opportunity_name
                ? opportunity_name.length > 14
                  ? opportunity_name.substring(0, 13) + ".."
                  : opportunity_name
                : contact_name
                ? contact_name.length > 14
                  ? contact_name.substring(0, 13) + ".."
                  : contact_name
                : carrier_name
                ? carrier_name.length > 14
                  ? carrier_name.substring(0, 13) + ".."
                  : carrier_name
                : account_name && account_name.length > 14
                ? account_name.substring(0, 13) + ".."
                : account_name}{" "}
              <i
                className="fa fa-circle"
                style={{ fontSize: "4px", transform: "translateY(-3px)" }}
              ></i>{" "}
              {name} by{" "}
              {created_by.length > 13
                ? created_by.substring(0, 12) + ".."
                : created_by}
              {category_id?.toString() === "2" && metadata?.status && (
                <span
                  className="activity-status"
                  style={
                    metadata?.status === "sent" ||
                    metadata?.status === "delivered"
                      ? {
                          background: "#F0FDF0",
                          color: "#15803D",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }
                      : metadata?.status === "scheduled" ||
                        metadata?.status === "queued"
                      ? {
                          color: "#cd8003",
                          background: "#FFF1D6",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }
                      : metadata?.status === "reply" ||
                        metadata?.status === "received"
                      ? {
                          color: "#351C75",
                          background: "#ddd8f2",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }
                      : {
                          background: "#F9E1E1",
                          color: "#D32F2F",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }
                  }
                  data-tip={
                    metadata?.status === "scheduled"
                      ? `Scheduled for ${moment(send_at.toString()).format(
                          "LLL"
                        )}`
                      : ""
                  }
                >
                  {metadata?.status === "reply"
                    ? "Reply"
                    : metadata?.status === "received"
                    ? "Reply Received"
                    : metadata?.status.charAt(0).toUpperCase() +
                      metadata?.status.slice(1)}
                </span>
              )}
            </div>
            <div
              className="activityLog__activity-title"
              style={{ lineHeight: "18px", marginTop: "5px" }}
            >
              {title}
              {category_id === 10 &&
                quoteData &&
                ` $${new Intl.NumberFormat("en-US").format(
                  quoteData.price
                )} saved for ${quoteData.origin_address
                  ?.replace(/\s*,?\s*(US|USA|United States)\s*$/i, "")
                  .trim()}
                  to
                  ${quoteData.destination_address
                    ?.replace(/\s*,?\s*(US|USA|United States)\s*$/i, "")
                    .trim()} moving via ${
                  (Array.isArray(quoteData.equipment_types) &&
                    quoteData.equipment_types
                      ?.map((item) => item?.name)
                      ?.join(", ")) ||
                  ""
                } with a ship date of ${moment(quoteData.shipment_date).format(
                  "L"
                )} `}
            </div>
            {metadata?.to_email?.length > 0 && (
              <div style={{ fontSize: "11px", color: "#586874" }}>
                to:{" "}
                {Array.isArray(metadata?.to_email)
                  ? metadata?.to_email?.map((email) => email)?.join("; ")
                  : metadata?.to_email}
              </div>
            )}
            {description && (
              <div
                className="activityLog__activity-description"
                style={{
                  whiteSpace: whiteSpace,
                  overflowWrap: "break-word",
                }}
              >
                <JoditEditor value={description} config={config} />
              </div>
            )}
            {metadataType === "calendly" && metadata?.event && (
              <div
                className="activityLog__activity-description"
                style={{ whiteSpace: whiteSpace, overflowWrap: "break-word" }}
              >
                <br />
                <span style={{ fontWeight: "500", marginRight: "10px" }}>
                  Start Time:
                </span>{" "}
                {moment(metadata?.event.start_time).format("LLL")}
                <br />
                <span style={{ fontWeight: "500", marginRight: "15px" }}>
                  End Time:
                </span>{" "}
                {moment(metadata?.event.end_time).format("LLL")}
              </div>
            )}
            {metadata?.unsubscribed && (
              <div
                style={{
                  background: "#FFF2F1",
                  padding: "8px",
                  color: "#E24F49",
                  border: "1px solid #FED4D4",
                  borderRadius: "4px",
                  margin: "10px -30px",
                  width: "100%",
                }}
              >
                <TriangleAlert size={16} /> Recipient Unsubscribed
                <p style={{ color: "#CB5552" }}>
                  This contact has opted out of email communications. We advise
                  to no longer send them emails, as this is an indicator they
                  don’t want to receive emails. If you continue to send them
                  emails, you run the risk of them blocking you or marking as
                  spam, which can negatively impact your email deliverability.
                </p>
              </div>
            )}
            <div className="activityLog__activity-name">
              {attachments?.map((attachment) => (
                <a
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                  }}
                  key={attachment.name + attachment.url}
                >
                  <span
                    key={attachment.name + attachment.url}
                    className="activity-status"
                    style={{
                      background: "#F3F4F6",
                      color: "#727987",
                      fontWeight: "500",
                      textTransform: "capitalize",
                    }}
                  >
                    <span style={{ transform: "translateY(-2px)" }}>
                      <FileText size={12} />
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      {attachment.name && attachment.name.length > 14
                        ? attachment.name.substring(0, 13) + ".."
                        : attachment.name}
                    </span>
                  </span>
                </a>
              ))}
              {category_id?.toString() === "2" &&
              (metadata?.opened || metadata?.spam) ? (
                <span
                  className="activity-status"
                  style={{
                    background: metadata?.spam ? "#F9E1E1" : "#F0FDF0",
                    color: metadata?.spam ? "#D32F2F" : "#15803D",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                  data-tip={
                    metadata?.opened
                      ? moment(metadata?.first_opened_at).format("lll")
                      : ""
                  }
                >
                  {metadata?.spam ? (
                    <>
                      <Ban size={12} /> Marked as spam
                    </>
                  ) : (
                    <>
                      <MailOpen size={12} /> Opened ({metadata?.open_count})
                    </>
                  )}
                </span>
              ) : null}
            </div>
            <div style={{ display: "flex" }}>
              {result_name && (
                <div
                  className="activityLog__activity-description"
                  style={{
                    color: "#586874",
                    marginTop: "5px",
                    fontSize: "12px",
                  }}
                >
                  <img src={ResultIcon} alt="Result:" width="10" />{" "}
                  {result_name}
                </div>
              )}
              {metadata !== null &&
                metadataType !== "calendly" &&
                metadata?.status !== "failed" &&
                category_id?.toString() === "2" && (
                  <button
                    className="activityLog__activity-replyButton"
                    type="button"
                    onClick={() => setShowModal(true)}
                    data-tip={
                      metadata?.service_provider !== "postmark" &&
                      metadata?.service_provider !== mailProvider
                        ? `${mailProvider} is not connected.`
                        : ""
                    }
                    disabled={
                      metadata?.service_provider !== "postmark" &&
                      metadata?.service_provider !== mailProvider
                    }
                    style={
                      metadata?.service_provider !== "postmark" &&
                      metadata?.service_provider !== mailProvider
                        ? {
                            color: "rgb(167, 171, 170)",
                          }
                        : {}
                    }
                  >
                    Reply
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export class DeleteActivityModal extends React.Component {
  state = {
    showModal: true,
    loading: false,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  deleteHandler = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    axios({
      method: "DELETE",
      url: `/activities/${this.props.id}`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        this.props.loadActivities(1, "refetch");
        this.props.handleCloseModal();
        if (this.props.loadQuoteActivities) {
          this.props.loadQuoteActivities(1);
        }
        NotificationManager.success("Activity deleted");
        this.setState({ loading: false });
      })
      .catch((err) => {
        NotificationManager.error("Failed to delete activity");
        this.setState({ loading: false });
      });
  };
  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "26vh", top: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Delete Activity</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <form onSubmit={this.deleteHandler}>
                <p>Are you sure you want to delete this activity?</p>
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal__confirm-button"
                    disabled={this.state.loading}
                  >
                    {this.state.loading ? "Deleting" : "Delete"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default Activity;
