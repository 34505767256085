import React from "react";
import ReactModal from "react-modal";
import ClearIcon from "@mui/icons-material/Clear";
import { connect } from "react-redux";
import CustomQuote from "../../CustomQuote/CustomQuote";
import Quotes from "../Quotes";
import { withRouter } from "react-router-dom";

const AddQuoteModal = (props) => {
  const { isQuoteFieldsModified } = props;

  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };

  return (
    <ReactModal
      isOpen={props.showModal}
      contentLabel="onRequestClose Example"
      onRequestClose={props.handleCloseModal}
      style={modalStyle}
      ariaHideApp={false}
    >
      <div className="muiModal">
        <div
          className="modal__content"
          style={{ height: "70vh", top: `6%`, width: "30vw" }}
        >
          <div className="modal__header">
            <div className="modal__head">
              <h2 className="modal__heading">Add Quote</h2>
            </div>
            <button
              onClick={props.handleCloseModal}
              className="modal__close"
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                top: "24px",
                cursor: "pointer",
              }}
            >
              {" "}
              <ClearIcon fontSize="small" />
            </button>
          </div>
          <div className="modal__form">
            {isQuoteFieldsModified ? (
              <CustomQuote
                accountId={props.customerId}
                categoryId={10}
                getTasks={() => {}}
                opportunity_id={""}
                loadActivities={props.loadActivities}
                getAccountLanes={props.getAccountLanes}
                loadQuoteActivities={props.loadQuoteActivities}
                contact_id={""}
                setFormFields={() => {}}
                closeModal={props.handleCloseModal}
                isModal={true}
              />
            ) : (
              <Quotes
                companyOnboarding={props.companyOnboarding}
                equipmentTypes={props.allEquipmentTypes}
                modes={props.allModes}
                accountId={props.customerId}
                categoryId={10}
                getTasks={() => {}}
                opportunity_id={""}
                loadActivities={props.loadActivities}
                getAccountLanes={props.getAccountLanes}
                loadQuoteActivities={props.loadQuoteActivities}
                contact_id={""}
                setFormFields={() => {}}
                closeModal={props.handleCloseModal}
                isModal={true}
              />
            )}
          </div>
          <div className="modal__buttons">
            <button
              type="button"
              className="modal__cancel-button"
              onClick={props.handleCloseModal}
              style={{ zIndex: "auto" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

const MSP = (state) => {
  return {
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allModes: state.allModes.modes,
    companyOnboarding: state.login.companyOnboarding,
    isQuoteFieldsModified: state.allQuotes.isModified,
  };
};

export default connect(MSP)(withRouter(AddQuoteModal));
