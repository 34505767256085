import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "../Reusables/InputField/InputField";
import MuiSelect from "../Reusables/Select/MuiSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MenuItem } from "@mui/material";
import Select from "react-select";

import { Check } from "lucide-react";
import moment from "moment";

import { Hidden, Visible } from "react-grid-system";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import GoogleAutocomplete from "./GoogleAutocomplete";
import { customStylesErr } from "../../constants/selectStyles";

const Quotes = (props) => {
  const quoteStatus = ["Pending", "Won", "Lost"];
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [stopFields, setStopFields] = useState([]);
  const [stopLocations, setStopLocations] = useState([]); // Add this new state
  const [resetCounter, setResetCounter] = useState(0);

  // Add this function to handle adding new origin fields
  const addStopField = () => {
    if (stopFields.length < 10) {
      setStopFields([...stopFields, { id: stopFields.length + 1 }]);
    }
  };

  // Validation Schema
  const schema = Yup.object().shape({
    shipDate: Yup.date().required("Ship date is required"),
    deliveryDate: Yup.date().nullable(),
    status: Yup.string().required("Status is required"),
    origin: Yup.object()
      .shape({
        address: Yup.string()
          .nullable()
          .required("Origin location is required"),
        lat: Yup.number().nullable(),
        long: Yup.number().nullable(),
        city: Yup.string().nullable(),
        state: Yup.string().nullable(),
        zip: Yup.string().nullable(),
        country: Yup.string().nullable(),
      })
      .nullable()
      .required("Origin is required"),
    destination: Yup.object()
      .shape({
        address: Yup.string()
          .nullable()
          .required("Destination location is required"),
        lat: Yup.number().nullable(),
        long: Yup.number().nullable(),
        city: Yup.string().nullable(),
        state: Yup.string().nullable(),
        zip: Yup.string().nullable(),
        country: Yup.string().nullable(),
      })
      .nullable()
      .required("Destination is required"),
    equipmentTypes: Yup.array()
      .min(1, "Equipment type is required")
      .required("Equipment types are required")
      .nullable(),
    modes: Yup.array().nullable(),
    quote: Yup.number()
      .positive("Quote must be positive")
      .nullable()
      .required("Quote is required"),
  });

  // Initial Form Values
  const initialValues = {
    shipDate: new Date(),
    deliveryDate: null,
    status: "Pending",
    origin: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    destination: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    equipmentTypes: [],
    modes: [],
    quote: "",
  };
  const submitQuote = (values, { resetForm, setFieldValue }) => {
    const {
      shipDate,
      deliveryDate,
      status,
      origin,
      destination,
      equipmentTypes,
      modes,
      quote,
    } = values;

    const convertDate = moment(shipDate).format("YYYY-MM-DD");
    const convertDeliveryDate = deliveryDate
      ? moment(deliveryDate).format("YYYY-MM-DD")
      : null;

    setLoading(true);
    axios({
      method: "POST",
      url: `/activities/create`,
      data: {
        name: "Quote",
        shipment_date: convertDate,
        delivery_date: convertDeliveryDate,
        status: status.toLowerCase(),
        origin_city: origin.city,
        origin_state: origin.state,
        origin_zip: origin.zip,
        origin_address: origin.address,
        origin_lat: origin.lat,
        origin_long: origin.long,
        destination_city: destination.city,
        destination_state: destination.state,
        destination_zip: destination.zip,
        destination_address: destination.address,
        destination_lat: destination.lat,
        destination_long: destination.long,
        equipment_type_ids: equipmentTypes.map((item) => item.value),
        mode_ids: modes.map((item) => item.value),
        type_ids: [],
        requirement_type_ids: [],
        price: quote,
        account_id: props.accountId,
        category_id: props.categoryId,
        type: "activity_logged",
        stops: stopLocations,
        ...(props.opportunity_id !== "" &&
          props.opportunity_id !== null && {
            opportunity_id: props.opportunity_id,
          }),
        ...(props.contact_id !== "" &&
          props.contact_id !== null && {
            contact_id: props.contact_id,
          }),
      },
    })
      .then((res) => {
        resetForm();

        setFieldValue("origin", {
          address: null,
          lat: null,
          long: null,
          city: null,
          state: null,
          zip: null,
          country: null,
        });
        setFieldValue("destination", {
          address: null,
          lat: null,
          long: null,
          city: null,
          state: null,
          zip: null,
          country: null,
        });

        setStopFields([]);
        setResetCounter((prev) => prev + 1);

        NotificationManager.success("Quote Saved Successfully!");
        props.loadActivities(1, "refetch");
        props.getAccountLanes(1, "refetch");
        props.loadQuoteActivities();
        setErrorMessage(false);
        setLoading(false);
        props.getTasks();
        props.setFormFields({
          account_id: props.accountId,
          opportunity_id: "",
          contact_id: "",
          category_id: 1,
          type: "activity_logged",
        });
        if (props.closeModal) {
          props.closeModal();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      outline: "#C0EAD8",
      padding: "1px 0px",
      // margin: "5px auto",
      // height: "35px",
      boxShadow: "none",
      borderColor: "lightgray",
      ":active": {
        ...styles[":active"],
        border: "2px solid #4A9876",
        outline: "#4A9876",
      },
      ":hover": {
        ...styles[":hover"],
        border: "2px solid #4A9876",
      },
      ":focus": {
        ...styles[":hover"],
        border: "2px solid #4A9876",
      },
    }),
    indicatorSeparator: (styles) => {
      return {
        display: "none",
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        // height: "37px",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#3aab7b"
          : isFocused
          ? "#C0EAD8"
          : null,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#C0EAD8",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "black",
    }),
  };
  return (
    <>
      {/* Forms */}
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={submitQuote}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({ errors, touched, values, handleChange, setFieldValue }) => (
          <Form>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "20px",
                marginTop: "5px",
                marginBottom: "20px",
                overflowY: "scroll",
                height: props.isModal ? "40vh" : "30vh",
                maxHeight: props.isModal ? "60vh" : "40vh",
                padding: "10px",
                WebkitOverflowScrolling: "touch",
              }}
            >
              <div
                className="controlsForms"
                style={{
                  width: "100%",
                  marginTop: "2px",
                }}
              >
                <DatePicker
                  selected={values.shipDate}
                  name="shipDate"
                  value={values.shipDate}
                  onChange={(date) => setFieldValue("shipDate", date)}
                  minDate={moment().toDate()}
                  className="datePickerQuotes"
                  placeholderText="Select Date"
                  dateFormat={
                    props.companyOnboarding?.date_format === "DD/MM/YYYY"
                      ? "dd/MM/yyyy"
                      : "MM/dd/yyyy"
                  }
                  autoComplete="off"
                  popperPlacement="auto"
                  popperProps={{
                    modifiers: [
                      {
                        name: "zIndex",
                        options: {
                          zIndex: 10,
                        },
                      },
                    ],
                  }}
                />
                <label className="activeLabel" style={{ fontSize: "12px" }}>
                  Ship Date
                </label>
                {errors.shipDate && touched.shipDate && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: "12px",
                    }}
                  >
                    {errors.shipDate}
                  </span>
                )}
              </div>
              <div
                className="controlsForms"
                style={{
                  width: "100%",
                  marginTop: "2px",
                }}
              >
                <DatePicker
                  selected={values.deliveryDate}
                  name="deliveryDate"
                  value={values.deliveryDate}
                  onChange={(date) => setFieldValue("deliveryDate", date)}
                  minDate={moment().toDate()}
                  className="datePickerQuotes"
                  placeholderText="Select Delivery Date"
                  dateFormat={
                    props.companyOnboarding?.date_format === "DD/MM/YYYY"
                      ? "dd/MM/yyyy"
                      : "MM/dd/yyyy"
                  }
                  autoComplete="off"
                  popperPlacement="auto"
                  popperProps={{
                    modifiers: [
                      {
                        name: "zIndex",
                        options: {
                          zIndex: 10,
                        },
                      },
                    ],
                  }}
                />
                <label className="activeLabel" style={{ fontSize: "12px" }}>
                  Delivery Date
                </label>
              </div>

              {/* Origin */}
              <div className="controlsForms" style={{ width: "100%" }}>
                <div>
                  <GoogleAutocomplete
                    name="origin"
                    className={
                      touched.origin && errors.origin
                        ? "formField-err"
                        : "formField"
                    }
                    valueProps={values?.origin?.address}
                    resetTrigger={resetCounter}
                    onPlaceSelect={(place) => setFieldValue("origin", place)}
                  />
                  {stopFields.length < 10 && (
                    <button
                      type="button"
                      onClick={addStopField}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        position: "absolute",
                        width: "30px",
                        height: "30px",
                        right: "-25px",
                        top: props.isModal ? "40%" : "50%",
                        transform: "translateY(-50%)", // Center vertically
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0,
                        color: "#666",
                      }}
                    >
                      <span
                        title="Add Stop"
                        style={{ fontSize: "20px", lineHeight: 0 }}
                      >
                        +
                      </span>
                    </button>
                  )}
                </div>
                <label
                  className="activeLabel"
                  style={{
                    color: touched.origin && errors.origin ? "#d32f2f" : "",
                    fontSize: "12px",
                    top: "-12px",
                  }}
                >
                  Origin
                </label>
                {touched.origin && errors.origin && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: "12px",
                    }}
                  >
                    {typeof errors.origin === "object" && errors.origin?.address
                      ? errors.origin.address
                      : "Origin is required"}
                  </span>
                )}
              </div>
              {/* MultiStops  */}
              {stopFields.map((field, index) => (
                <div
                  key={field.id}
                  className="controlsForms"
                  style={{ width: "100%" }}
                >
                  <div>
                    <GoogleAutocomplete
                      name={`origin${field.id}`}
                      className="formField"
                      valueProps={
                        stopLocations[index]?.address
                          ? stopLocations[index]?.address
                          : undefined
                      }
                      onPlaceSelect={(place) =>
                        // Update the stops array, replacing the stop at this index if it exists
                        setStopLocations((prevStops) => {
                          const newStops = [...prevStops];
                          newStops[index] = place;
                          return newStops;
                        })
                      }
                      placeholder="Enter a stop location"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        // Remove field and reindex remaining fields
                        setStopFields((fields) => {
                          const newFields = fields
                            .filter((_, i) => i !== index)
                            .map((field, i) => ({ id: i + 1 }));
                          return newFields.length ? newFields : [];
                        });

                        // Remove stop and compact remaining stops
                        setStopLocations((prevStops) => {
                          const newStops = prevStops.filter(
                            (_, i) => i !== index
                          );
                          return newStops.length ? newStops : [];
                        });
                      }}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        position: "absolute",
                        width: "30px",
                        height: "30px",
                        right: "-25px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0,
                        color: "#666",
                        zIndex: 2,
                      }}
                    >
                      <span
                        title={`Remove Stop ${index + 1}`}
                        style={{ fontSize: "20px", lineHeight: 0 }}
                      >
                        ×
                      </span>
                    </button>
                  </div>
                  <label
                    className="activeLabel"
                    style={{ top: "-12px", fontSize: "12px" }}
                  >
                    Stop {index + 1}
                  </label>
                </div>
              ))}
              {/* Destination */}
              <div className="controlsForms" style={{ width: "100%" }}>
                <div>
                  <GoogleAutocomplete
                    name="destination"
                    className={
                      touched.destination && errors.destination
                        ? "formField-err"
                        : "formField"
                    }
                    valueProps={values?.destination?.address}
                    resetTrigger={resetCounter}
                    onPlaceSelect={(place) =>
                      setFieldValue("destination", place)
                    }
                  />
                </div>
                <label
                  className="activeLabel"
                  style={{
                    color:
                      touched.destination && errors.destination
                        ? "#d32f2f"
                        : "",
                    top: "-12px",
                    fontSize: "12px",
                  }}
                >
                  Destination
                </label>
                {touched.destination && errors.destination && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: "12px",
                    }}
                  >
                    {typeof errors.destination === "object" &&
                    errors.destination?.address
                      ? errors.destination.address
                      : "Destination is required"}
                  </span>
                )}
              </div>
              <MuiSelect
                name="status"
                value={values.status}
                error={errors.status}
                helperText={errors.status}
                label="Status"
                sx={{ marginBottom: "0", zIndex: 0 }}
                onChange={handleChange}
                placeholder="State"
                menuItem={quoteStatus.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              />
              <div className="controlsForms" style={{ width: "100%" }}>
                <Select
                  name="equipmentTypes"
                  value={values.equipmentTypes}
                  closeMenuOnSelect={false}
                  isMulti
                  styles={
                    errors.equipmentTypes && touched.equipmentTypes
                      ? customStylesErr
                      : colourStyles
                  }
                  options={props.equipmentTypes?.map((type) => {
                    return { value: type.id, label: type.name };
                  })}
                  placeholder="-Select-"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    setFieldValue("equipmentTypes", selectedOptions)
                  }
                />
                <label
                  className="activeLabel"
                  style={{
                    color:
                      errors.equipmentTypes && touched.equipmentTypes
                        ? "#d32f2f"
                        : "",
                    top: "-14px",
                    fontSize: "12px",
                    height: "10px",
                  }}
                >
                  Equipment Types
                </label>
                {errors.equipmentTypes && touched.equipmentTypes && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: "12px",
                    }}
                  >
                    {errors.equipmentTypes}
                  </span>
                )}
              </div>
              <div className="controlsForms" style={{ width: "100%" }}>
                <Select
                  name="modes"
                  value={values.modes}
                  closeMenuOnSelect={false}
                  isMulti
                  styles={
                    errors.modes && touched.modes
                      ? customStylesErr
                      : colourStyles
                  }
                  options={props.modes?.map((type) => {
                    return { value: type.id, label: type.name };
                  })}
                  placeholder="-Select-"
                  className="basic-multi-select"
                  menuPlacement="top"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    setFieldValue("modes", selectedOptions)
                  }
                />
                <label
                  className="activeLabel"
                  style={{
                    color: errors.modes && touched.modes ? "#d32f2f" : "",
                    top: "-14px",
                    height: "10px",
                    fontSize: "12px",
                  }}
                >
                  Modes
                </label>
                {errors.modes && touched.modes && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: "12px",
                    }}
                  >
                    {errors.modes}
                  </span>
                )}
              </div>
              <InputField
                label="Quote"
                name="quote"
                type="number"
                sx={{ width: "100%", zIndex: 0 }}
                value={values.quote}
                onChange={handleChange}
                error={errors.quote && touched.quote}
                helperText={errors.quote}
                onWheel={(e) => e.target.blur()}
              />
            </div>

            {/* Save Button */}
            <Hidden md sm xs>
              <div style={{ textAlign: "right" }}>
                <button
                  tabIndex="0"
                  className="button-md"
                  type="submit"
                  style={
                    props.isModal
                      ? { position: "absolute", bottom: "20px", right: "6%" }
                      : {}
                  }
                >
                  <span
                    style={{
                      transform: "translateY(-2px)",
                      display: "inline-block",
                    }}
                    disabled={loading}
                  >
                    {loading ? "Saving" : "Save Quote"}
                  </span>
                  <Check
                    size={16}
                    color="#62CA9D"
                    style={{
                      transform: "translateY(2px)",
                      marginLeft: "5px",
                    }}
                  />
                </button>
              </div>
            </Hidden>
            <Visible md sm xs>
              <div>
                <button
                  tabIndex="0"
                  className="button-md"
                  type="submit"
                  style={
                    props.isModal
                      ? { position: "absolute", bottom: "20px", right: "6%" }
                      : {}
                  }
                >
                  {loading ? "Saving" : "Save Quote"}
                </button>
              </div>
            </Visible>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Quotes;
