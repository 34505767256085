import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Pagination from "react-js-pagination";
// import PauseSequenceModal from "./PauseSequenceModal";
import DropdownAnalyticsFilter from "../Dropdown/DropdownAnalyticsFilter";
import DropdownCustomRangeFilter from "../Dropdown/DropdownCustomRangeFilter";
import LineChartAnalytics from "../EmailAnalytics/LineGraphAnalytics";
import "../EmailAnalytics/EmailAnalytics.scss";
import { truncateDecimals } from "../../utils/Helper/reusableFunctions";
import DropdownDashboard from "../Dropdown/DropdownDashboard";
//images
import clickEmailIcon from "../../images/clickEmailIcon.png";
import openEmailIcon from "../../images/openEmailIcon.png";
import replyEmailIcon from "../../images/replyEmailIcon.png";
import spamEmailIcon from "../../images/spamEmailIcon.png";
import totalSentIcon from "../../images/totalSentIcon.png";
import undeliveredEmailIcon from "../../images/undeliveredEmailIcon.png";
import unsubscribeEmailIcon from "../../images/unsubscribe.png";
// Redux stuff
import { connect } from "react-redux";
import {
  companyOnboardingSet,
  setTab,
  setActiveTabIndex,
  authUserSet,
} from "../../redux";
import PauseSequenceModal from "./PauseSequenceModal";

const Sequencing = (props) => {
  const [stats, setStats] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [sequenceId, setSequenceId] = useState(null);
  // const [action, setAction] = useState("");
  const [sortDirection, setSortDirection] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(["active"]);
  const [selectedDateFilter, setSelectedDateFilter] = useState("last_4_weeks");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [analyticsMode, setAnalyticsMode] = useState("table");
  const [chartSeries, setChartSeries] = useState([]);
  const [user_ids, setUser_ids] = useState(props.authUser.id);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayUserName, setDisplayUserName] = useState(props.authUser.name);
  const [metrics, setMetrics] = useState({
    bounced: { count: 0, rate: 0 },
    clicked: { count: 0, rate: 0 },
    replied: { count: 0, rate: 0 },
    opened: { count: 0, rate: 0 },
    sent: 0,
    scheduled: 0,
    spam: { count: 0, rate: 0 },
    unsubscribed: { count: 0, rate: 0 },
  });
  // constants
  const grids = [
    {
      name: "total sent",
      img: totalSentIcon,
      matrix: metrics.sent,
      matrixPercentage: null,
      type: "sent",
    },
    {
      name: "opens",
      img: openEmailIcon,
      matrix: metrics.opened.count,
      matrixPercentage: metrics.opened.rate,
      type: "opened",
    },
    {
      name: "clicks",
      img: clickEmailIcon,
      matrix: metrics.clicked.count,
      matrixPercentage: metrics.clicked.rate,
      type: "clicked",
    },
    {
      name: "replies",
      img: replyEmailIcon,
      matrix: metrics.replied.count,
      matrixPercentage: metrics.replied.rate,
      type: "replied",
    },
    {
      name: "bounces",
      img: undeliveredEmailIcon,
      matrix: metrics.bounced.count,
      matrixPercentage: metrics.bounced.rate,
      type: "bounced",
    },
    {
      name: "spam",
      img: spamEmailIcon,
      matrix: metrics.spam.count,
      matrixPercentage: metrics.spam.rate,
      type: "spam",
    },
    {
      name: "unsubscribes",
      img: unsubscribeEmailIcon,
      matrix: metrics.unsubscribed.count,
      matrixPercentage: metrics.unsubscribed.rate,
      type: "unsubscribed",
    },
  ];
  const dateFilters = [
    { label: "Last 7 days", value: "last_7_days" },
    { label: "Month to date", value: "this_month" },
    { label: "Last 4 weeks", value: "last_4_weeks" },
    { label: "Last 3 months", value: "last_3_months" },
    { label: "Last 6 months", value: "last_6_months" },
    { label: "Last 12 months", value: "last_12_months" },
  ];
  const filters = ["active", "paused", "completed"];
  // functions
  // const filterHandler = (e) => {
  //   const { value } = e.target;
  //   if (e.target.checked) {
  //     const newFilters = [...selectedFilters, value];
  //     setSelectedFilters(newFilters);
  //   } else {
  //     const removeFilter = selectedFilters.filter((filter) => filter !== value);
  //     setSelectedFilters(removeFilter);
  //   }
  // };
  const dateChangeHandler = (date, name) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    name === "toDate" ? setToDate(offsetDate) : setFromDate(offsetDate);
  };
  const dateFilterChangeHandler = (value) => {
    setSelectedDateFilter(value);
    setToDate("");
    setFromDate("");
  };
  const applyCustomDateFilter = () => {
    setSelectedDateFilter("custom_range");
    getStats(1, null, user_ids);
  };

  const getStats = (pageParam, sortKey, user_ids = props.authUser.id) => {
    let url1 = `/sequences/scheduled/history?_limit=30&_page=${pageParam}`;
    let url2 = `/sequences/stats-charts?_limit=30&_page=${pageParam}`;
    let url3 = `/sequences/stats?_limit=30&_page=${pageParam}`;
    if (sortKey) {
      const dir = sortDirection === true ? "desc" : "asc";
      url1 += `&sort_key=${sortKey}&sort_dir=${dir}`;
      url2 += `&sort_key=${sortKey}&sort_dir=${dir}`;
      url3 += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    if (selectedDateFilter === "custom_range") {
      url1 += `&start_date_from=${moment(fromDate).format(
        "YYYY-MM-DD"
      )}&end_date_to=${moment(toDate).format("YYYY-MM-DD")}`;
      url2 += `&start_date_from=${moment(fromDate).format(
        "YYYY-MM-DD"
      )}&end_date_to=${moment(toDate).format("YYYY-MM-DD")}`;
      url3 += `&start_date_from=${moment(fromDate).format(
        "YYYY-MM-DD"
      )}&end_date_to=${moment(toDate).format("YYYY-MM-DD")}`;
    } else {
      url1 += `&time=${selectedDateFilter}`;
      url2 += `&time=${selectedDateFilter}`;
      url3 += `&time=${selectedDateFilter}`;
    }
    if (user_ids) {
      url1 += `&user_ids=${user_ids}`;
      url2 += `&user_ids=${user_ids}`;
      url3 += `&user_ids=${user_ids}`;
    }
    setLoading(true);
    const requestOne = axios.get(url1);
    const requestTwo = axios.get(url2);
    const requestThree = axios.get(url3);
    axios
      .all([requestOne, requestTwo, requestThree])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responesTwo = responses[1];
          const responesThree = responses[2];
          setStats(responseOne.data.sequences);
          setTotal(responseOne.data.total);
          setChartSeries(responesTwo.data.chart_data);
          setMetrics(responesThree.data.stats);
          setLoading(false);
        })
      )
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getStats(1, null, user_ids);
  }, [selectedDateFilter, selectedFilters]);
  const tableHeaders = [
    { key: "name", title: "Name", sort: false },
    { key: "status", title: "Status", sort: false },
    { key: "records_enrolled", title: "Records Enrolled", sort: true },
    { key: "records_type", title: "Records Type", sort: true },
    { key: "sent", title: "Sent", sort: true },
    { key: "failed", title: "Failed", sort: true },
    { key: "delivered", title: "Delivered", sort: true },
    { key: "opened", title: "Opened", sort: true },
    { key: "replied", title: "Replied", sort: true },
    { key: "bounced", title: "Bounced", sort: true },
    { key: "clicked", title: "Clicked", sort: true },
    { key: "spam", title: "Spam", sort: true },
    { key: "unsubscribed", title: "Unsubscribed", sort: true },
    { key: "created_at", title: "Created at", sort: true },
    { key: "", title: "ACTION", sort: false },
  ];
  const handleUserId = (user_ids, userName) => {
    setUser_ids(user_ids);
    setDisplayMenu(!displayMenu);
    setDisplayUserName(userName);
    getStats(1, null, user_ids);
  };
  const showDropdownMenu = (event) => {
    event.preventDefault();
    setDisplayMenu(!displayMenu);
  };
  return (
    <div className="reports">
      <PauseSequenceModal
        showOpportunityModal={showModal}
        setShowOpportunityModal={() => setShowModal(true)}
        handleCloseModal={() => setShowModal(false)}
        sequenceId={sequenceId}
        getStats={getStats}
      />
      <ReactTooltip multiline={true} id="beta-tooltip" place="right" />
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <h2 style={{ marginBottom: "0px" }}>
          Email Sequences
          <Link
            to="/sequencing-create"
            className="button-md"
            style={{
              float: "right",
              fontSize: "13.33px",
              textDecoration: "none",
              justifyContent: "center",
              textAlign: "center",
              paddingTop: "8px",
              height: "26px",
              width: "110px",
            }}
          >
            Create Sequence
          </Link>
          <div style={{ display: "inline-block", marginLeft: "50px" }}>
            <DropdownDashboard
              handleUserId={handleUserId}
              user_ids={user_ids}
              userName={props.authUser.name}
              userId={props.authUser.id}
              displayMenu={displayMenu}
              showDropdownMenu={showDropdownMenu}
              displayUserName={displayUserName}
              userRole={props.authUser.role}
            />
          </div>
        </h2>
        <p> </p>
      </div>
      <div className="email-analytics-filters">
        <h3>Filters: </h3>
        <div style={{ display: "flex" }}>
          {/* {filters.map((filter) => (
            <label
              className="coloredTasks-menu"
              style={
                selectedFilters.includes(filter)
                  ? {
                      border: "2px solid #4A9876",
                      textTransform: "capitalize",
                      fontSize: "14px",
                    }
                  : { textTransform: "capitalize", fontSize: "14px" }
              }
            >
              <input
                value={filter}
                onChange={filterHandler}
                style={{ display: "none" }}
                type="checkbox"
                checked={selectedFilters.includes(filter)}
                disabled={
                  selectedFilters.length === 1 &&
                  selectedFilters.includes(filter)
                }
              />
              {filter}
            </label>
          ))} */}
          <div className="email-analytics-range">
            <DropdownAnalyticsFilter
              selectedDateFilter={selectedDateFilter}
              dateFilters={dateFilters}
              dateFilterChangeHandler={dateFilterChangeHandler}
              toDate={toDate}
              fromDate={fromDate}
            />
          </div>
          <div style={{ margin: "0 20px 0 0" }}>
            <DropdownCustomRangeFilter
              toDate={toDate}
              fromDate={fromDate}
              dateChangeHandler={dateChangeHandler}
              submitHandler={applyCustomDateFilter}
            />
          </div>
        </div>
      </div>
      <div className="email-analytics-grid">
        {grids.map((grid) => {
          let newTabData = {
            type: "sequenceCampaignMetrices",
            id: grid.type,
            name: grid.name,
            campaignType: grid.type,
            time: selectedDateFilter,
            start_time: fromDate,
            end_time: toDate,
          };
          return (
            <div
              className="email-analytics-metrics"
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  props.setTab({ ...newTabData, blank: true });
                } else {
                  props.setActiveTabIndex(props.totalTabs + 1);
                  props.setTab(newTabData);
                  props.history.push("/active-tabs");
                }
              }}
            >
              <img src={grid.img} />
              <div>
                <div className="email-analytics-amount">
                  {grid.matrixPercentage === null
                    ? grid?.matrix
                    : `${truncateDecimals(grid?.matrixPercentage, 1)}%`}
                </div>
                <div className="email-analytics-infoname">{grid.name}</div>
                <div className="email-analytics-info">
                  {grid.matrixPercentage !== null &&
                    `${grid?.matrix || 0} ${grid.name}`}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="reports-tab-container">
        <button
          className="reports-tab"
          type="button"
          onClick={() => setAnalyticsMode("table")}
          data-tip="Tabular view"
        >
          <i className="fa fa-table" aria-hidden="true"></i>
        </button>
        <button
          className="reports-tab"
          type="button"
          onClick={() => setAnalyticsMode("line")}
          data-tip="Line chart"
        >
          <i className="fa fa-line-chart" aria-hidden="true"></i>
        </button>
      </div>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : loading === false && stats.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            fontWeight: "500",
          }}
        >
          No sequences found
        </div>
      ) : analyticsMode === "table" ? (
        <div style={{ width: "96vw", height: "auto", overflowX: "auto" }}>
          <table
            className="accountList__table"
            style={{ marginTop: "20px", width: "93vw", tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                {tableHeaders.map((header) => (
                  <th>
                    <Row>
                      <Col lg={9} xl={9}>
                        {header.title}
                      </Col>
                      {header.sort && (
                        <Col lg={3} xl={3}>
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                              color: "#657885",
                            }}
                            onClick={() => {
                              setSortDirection(!sortDirection);
                              getStats(activePage, header.key, user_ids);
                            }}
                          >
                            <i className="fa fa-long-arrow-up"></i>
                            <i className="fa fa-long-arrow-down"></i>
                          </button>
                        </Col>
                      )}
                    </Row>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={{ fontSize: "16px" }}>
              {stats?.map((stat) => {
                const {
                  id,
                  metadata,
                  opened,
                  spam,
                  unsubscribed,
                  created_at,
                  delivered,
                  replied,
                  bounced,
                  clicked,
                  records_enrolled,
                  sent,
                  failed,
                  status,
                  records_type,
                } = stat;
                const newTabData = {
                  type: metadata?.type,
                  id: metadata?.type_id,
                  name: metadata?.type_name,
                };
                const historyTabData = {
                  type: "sequenceHistory",
                  id: id,
                  name: `${metadata?.type_name} Details`,
                };
                return (
                  <tr>
                    <td>
                      <span
                        style={{ cursor: "pointer", color: "#4A9876" }}
                        onClick={(e) => {
                          if (e.metaKey || e.ctrlKey) {
                            props.setTab({ ...newTabData, blank: true });
                          } else {
                            props.setActiveTabIndex(props.totalTabs + 1);
                            props.setTab(newTabData);
                            props.history.push("/active-tabs");
                          }
                        }}
                      >
                        {metadata?.type_name}
                      </span>
                    </td>
                    <td>
                      <span
                        style={
                          status === "cancelled" || status === "failed"
                            ? {
                                background: "#F9E1E1",
                                color: "#D32F2F",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }
                            : status === "inprogress" || status === "queued"
                            ? {
                                color: "#cd8003",
                                background: "#FFF1D6",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }
                            : status === "reply" || status === "received"
                            ? {
                                color: "#351C75",
                                background: "#ddd8f2",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }
                            : {
                                background: "#F0FDF0",
                                color: "#15803D",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }
                        }
                      >
                        {status === "inprogress" ? "In Progress" : status}
                      </span>{" "}
                      <div
                        style={{
                          cursor: "pointer",
                          color: "#4A9876",
                        }}
                        onClick={(e) => {
                          if (e.metaKey || e.ctrlKey) {
                            props.setTab({ ...historyTabData, blank: true });
                          } else {
                            props.setActiveTabIndex(props.totalTabs + 1);
                            props.setTab(historyTabData);
                            props.history.push("/active-tabs");
                          }
                        }}
                      >
                        View Seq. Details {">>"}
                      </div>
                    </td>
                    <td>{records_enrolled}</td>
                    <td>
                      <span style={{ textTransform: "capitalize" }}>
                        {records_type}
                      </span>
                    </td>
                    <td>{sent}</td>
                    <td>{failed}</td>
                    <td>{delivered}</td>
                    <td>{opened}</td>
                    <td>{replied}</td>
                    <td>{bounced}</td>
                    <td>{clicked}</td>
                    <td>{spam}</td>
                    <td>{unsubscribed}</td>
                    <td>{moment(created_at).format("LLL")}</td>
                    <td>
                      {status === "inprogress" && (
                        <button
                          type="button"
                          onClick={() => {
                            setSequenceId(id);
                            setShowModal(true);
                          }}
                          className="button-sm"
                        >
                          Stop
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <LineChartAnalytics series={chartSeries} />
      )}
      <Pagination
        activePage={activePage}
        itemsCountPerPage={30}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          setActivePage(page);
          getStats(page, null, user_ids);
        }}
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
      />
    </div>
  );
};

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
    totalTabs: state.tabsState.tabs.length,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};
export default connect(MSP, MDP)(Sequencing);
